import React from 'react'
import { useTranslation, useI18next, Link as i18nextLink } from 'gatsby-plugin-react-i18next'
import { MdLanguage } from 'react-icons/md'
import styled from '@emotion/styled'
import { Flex, Text, Stack, Button } from '@chakra-ui/core'

import { Logo } from '../globals'

const Link = styled(i18nextLink)`
  cursor: pointer;
  opacity: 0.85;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.25rem;
  width: 100%;

  &:hover {
    opacity: 1;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    font-size: 1rem;
  }
`

const TermsLink = styled(i18nextLink)`
  cursor: pointer;
  opacity: 0.85;
  font-size: 0.85rem;
`

function Footer() {
  const { t } = useTranslation()
  const { changeLanguage, language } = useI18next()

  return (
    <Flex
      backgroundColor="gray.800"
      color="white"
      paddingY={12}
      paddingX={{ base: 8, lg: 20 }}
      direction="column"
    >
      <Flex justify="space-between">
        <Logo marginBottom={2} />
        <Button
          onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
          variant="outline"
          variantColor="white"
          rightIcon={MdLanguage}
        >
          {language === 'es' ? 'English' : 'Español'}
        </Button>
      </Flex>
      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        isInline
        spacing={6}
        width="100%"
        shouldWrapChildren
        paddingBottom={6}
        marginBottom={6}
        borderBottom="1px solid rgba(255, 255, 255, 0.15)"
      >
        <Link to="/rooms">{t('nav.rooms')}</Link>
        <Link to="/luxury-homes">{t('nav.luxuryHomes')}</Link>
        {/*<Link to="/apartments">{t('nav.apartments')}</Link>*/}
        <Link to="/contact">{t('nav.contact')}</Link>
      </Stack>
      <Flex
        direction={{ base: 'column-reverse', lg: 'row' }}
        align="center"
        justify="space-between"
      >
        <Text color="white" opacity="0.85" fontSize="xs">
          {t('footer.copyright')}
        </Text>
        <Stack isInline spacing={6} shouldWrapChildren>
          <TermsLink to="/privacy-policy">{t('nav.privacyPolicy')}</TermsLink>
          <TermsLink to="/reservation-policy">{t('nav.reservationPolicy')}</TermsLink>
        </Stack>
      </Flex>
    </Flex>
  )
}

export default Footer
