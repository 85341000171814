import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { Flex, Link } from '@chakra-ui/core'
import { FaWhatsapp } from 'react-icons/fa'

function WhatsappButton() {
  const { t } = useTranslation()

  return (
    <Flex
      zIndex="1"
      position="fixed"
      bottom="0"
      right="0"
      backgroundColor="#4dc247"
      borderRadius="100px"
      size="60px"
      justify="center"
      align="center"
      color="white"
      marginBottom="20px"
      marginRight="90px"
      boxShadow="0px 0px 6px rgba(0,0,0,0.25)"
    >
      <Link
        href={`https://api.whatsapp.com/send?phone=5219981567985&text=${t('commons.whatsappText')}`}
        width="100%"
        height="100%"
        display="inline-grid"
        alignItems="center"
        justifyItems="center"
        target="_blank"
        rel="noreferrer"
      >
        <FaWhatsapp size="2.25rem" />
      </Link>
    </Flex>
  )
}

export default WhatsappButton
