import React, { useState } from 'react'
import { addDays } from 'date-fns'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import DatePicker, { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'

// reservas@tulumresorts.com

import 'react-datepicker/dist/react-datepicker.css'

import {
  Flex,
  Input,
  Button,
  Text,
  Stack,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  SimpleGrid,
  Box,
} from '@chakra-ui/core'

registerLocale('en', en)
registerLocale('es', es)

const WrapperInputDate = styled(Box)`
  .react-datepicker-wrapper {
    width: 100%;
  }
`

function FormContact({
  hideOnSuccess,
  lang,
  typeQuote,
  nameLabel,
  namePlaceholder,
  emailLabel,
  emailPlaceholder,
  emailRequiredText,
  emailInvalidText,
  checkInLabel,
  checkOutLabel,
  commentsLabel,
  commentsPlaceholder,
  buttonText,
  successMessage,
  onSubmit,
}) {
  const [success, setSuccess] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      checkIn: '',
      checkOut: '',
      comments: '',
      typeQuote,
    },
    validate: (values) => {
      const errors = {}

      if (!values.email) {
        errors.email = emailRequiredText
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = emailInvalidText
      }

      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, (success) => setSuccess(success))
      setSubmitting(false)
    },
  })

  if (hideOnSuccess && success) {
    return (
      <Flex padding={10} justify="center">
        <Text textAlign="center" fontWeight="bold" fontSize="xl">
          {successMessage}
        </Text>
      </Flex>
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    values,
    isSubmitting,
  } = formik

  return (
    <form onSubmit={handleSubmit}>
      <Stack width="100%" direction="column" spacing={6}>
        <SimpleGrid columns={2} gap={6}>
          <FormControl>
            <FormLabel htmlFor="name">{nameLabel}</FormLabel>
            <Input
              type="name"
              id="name"
              placeholder={namePlaceholder}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormControl>

          <FormControl isInvalid={errors.email && touched.email}>
            <FormLabel isRequired htmlFor="email">
              {emailLabel}
            </FormLabel>
            <Input
              placeholder={emailPlaceholder}
              type="email"
              id="email"
              aria-describedby="email-helper-text"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.checkIn && touched.checkIn}>
            <FormLabel htmlFor="checkIn">{checkInLabel}</FormLabel>
            <WrapperInputDate>
              <DatePicker
                selectsStart
                name="checkIn"
                locale={lang}
                minDate={addDays(new Date(), 1)}
                selected={values.checkIn}
                startDate={values.checkIn}
                endDate={values.checkOut}
                onChange={(date) => setFieldValue('checkIn', date)}
                autoComplete="off"
                customInput={<Input />}
              />
            </WrapperInputDate>
          </FormControl>
          <FormControl isInvalid={errors.checkOut && touched.checkOut}>
            <FormLabel htmlFor="checkOut">{checkOutLabel}</FormLabel>
            <WrapperInputDate>
              <DatePicker
                selectsEnd
                name="checkOut"
                locale={lang}
                minDate={addDays(values.checkIn || new Date(), 1)}
                startDate={values.checkIn}
                selected={values.checkOut}
                endDate={values.checkOut}
                onChange={(date) => setFieldValue('checkOut', date)}
                autoComplete="off"
                customInput={<Input />}
              />
            </WrapperInputDate>
          </FormControl>
        </SimpleGrid>

        <FormControl isInvalid={errors.comments && touched.comments}>
          <FormLabel htmlFor="comments">{commentsLabel}</FormLabel>
          <Textarea
            id="comments"
            placeholder={commentsPlaceholder}
            value={values.comments}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
        <Button variantColor="teal" size="lg" type="submit" isDisabled={isSubmitting}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  )
}

FormContact.propTypes = {
  hideOnSuccess: PropTypes.bool,
  lang: PropTypes.string,
  typeQuote: PropTypes.string,
  nameLabel: PropTypes.string,
  namePlaceholder: PropTypes.string,
  emailLabel: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  emailRequiredText: PropTypes.string,
  emailInvalidText: PropTypes.string,
  checkInLabel: PropTypes.string,
  checkOutLabel: PropTypes.string,
  commentsLabel: PropTypes.string,
  commentsPlaceholder: PropTypes.string,
  buttonText: PropTypes.string,
  successMessage: PropTypes.string,
  onSuccess: PropTypes.func,
}

FormContact.defaultProps = {
  hideOnSuccess: false,
  lang: 'en',
  typeQuote: '',
  nameLabel: '',
  namePlaceholder: '',
  emailLabel: '',
  emailPlaceholder: '',
  emailRequiredText: '',
  emailInvalidText: '',
  checkInLabel: '',
  checkOutLabel: '',
  commentsLabel: '',
  commentsPlaceholder: '',
  buttonText: '',
  successMessage: PropTypes.string,
  onSuccess: () => {},
}

export default FormContact
