import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import { Heading, Text } from '@chakra-ui/core'

export const H1 = ({ children, ...restProps }) => (
  <Heading as="h1" {...restProps}>
    {children}
  </Heading>
)

export const H2 = ({ children, ...restProps }) => (
  <Heading as="h2" fontWeight="light" fontSize={{ base: 'lg', md: '2xl' }} {...restProps}>
    {children}
  </Heading>
)

export const H3 = ({ children, ...restProps }) => (
  <Heading as="h3" fontSize="2xl" {...restProps}>
    {children}
  </Heading>
)

export const Logo = (props) => (
  <Link to="/">
    <Text
      fontSize="2xl"
      fontWeight="bold"
      color="white"
      display="flex"
      alignItems="center"
      opacity="1"
      height="100%"
      {...props}
    >
      TulumResorts
    </Text>
  </Link>
)
