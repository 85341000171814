import { atom } from 'recoil'

export const bookingParamsState = atom({
  key: 'bookingParamsState',
  default: {
    isOpen: false,
    id: '',
    room: '',
    checkIn: '',
    checkOut: '',
    promotion: '',
  },
})

export const modalParamsState = atom({
  key: 'modalParamsState',
  default: {
    isOpen: false,
  },
})
