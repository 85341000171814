import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@chakra-ui/core'

function Header({ logo, nav }) {
  return (
    <Box
      as="header"
      backgroundColor="gray.800"
      display="flex"
      justifyContent="space-between"
      height={{ base: 'auto', lg: 20 }}
      paddingX={{ base: 4, lg: 20 }}
      paddingTop={{ base: 3, lg: 0 }}
      flexDirection={{ base: 'column', lg: 'row' }}
    >
      {logo}
      {nav}
    </Box>
  )
}

Header.propTypes = {
  logo: PropTypes.node,
  nav: PropTypes.node,
}

Header.defaultProps = {
  logo: null,
  nav: null,
}

export default Header
