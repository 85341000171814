import React from 'react'
import { useTranslation, Link as i18nextLink } from 'gatsby-plugin-react-i18next'
import styled from '@emotion/styled'

import { Stack } from '@chakra-ui/core'

const Link = styled(i18nextLink)`
  cursor: pointer;
  opacity: 0.85;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  color: ${(props) => props.theme.colors.white};
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }
`

function Nav() {
  const { t } = useTranslation()

  return (
    <Stack isInline spacing={6} align="center" overflowX="auto" shouldWrapChildren>
      <Link to="/">{t('nav.home')}</Link>
      <Link to="/rooms">{t('nav.rooms')}</Link>
      <Link to="/luxury-homes">{t('nav.luxuryHomes')}</Link>
      {/* <Link to="/apartments">{t('nav.apartments')}</Link> */}
      <Link to="/contact">{t('nav.contact')}</Link>
    </Stack>
  )
}

export default Nav
