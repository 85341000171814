import React from 'react'
import { format } from 'date-fns'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useRecoilState } from 'recoil'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/core'
import { ThemeProvider, CSSReset, theme, Box, Flex, Text } from '@chakra-ui/core'

import { bookingParamsState, modalParamsState } from '../../store'

import Header from '../header'
import Footer from '../footer'
import Article from '../article'
import Nav from '../nav'
import BookingModal from '../bookingModal'
import FormQuote from '../formQuote'
import Modal from '../modal'
import { Logo, H1, H2, H3 } from '../globals'
import WhatsappButton from '../whatsappButton'

import CircularStdBookWoff from '../../fonts/CircularStd-Book.woff'
import CircularStdBookWoff2 from '../../fonts/CircularStd-Book.woff2'
import CircularStdMediumWoff from '../../fonts/CircularStd-Medium.woff'
import CircularStdMediumWoff2 from '../../fonts/CircularStd-Medium.woff2'
import CircularStdBlackWoff from '../../fonts/CircularStd-Black.woff'
import CircularStdBlackWoff2 from '../../fonts/CircularStd-Black.woff2'

const customTheme = {
  ...theme,
  breakpoints: ['480px', '720px', '1024px', '1280px', '1440px', '1760px'],
}

customTheme.breakpoints.sm = customTheme.breakpoints[0]
customTheme.breakpoints.md = customTheme.breakpoints[1]
customTheme.breakpoints.lg = customTheme.breakpoints[2]
customTheme.breakpoints.xl = customTheme.breakpoints[3]
customTheme.breakpoints.xx = customTheme.breakpoints[4]
customTheme.breakpoints.mw = customTheme.breakpoints[5]

const globalStyles = css`
  @font-face {
    font-family: CircularStd;
    font-style: normal;
    src: url(${CircularStdBookWoff2}) format('woff2'), url(${CircularStdBookWoff}) format('woff');
    font-display: swap;
    font-weight: 300;
  }

  @font-face {
    font-family: CircularStd;
    font-style: normal;
    src: url(${CircularStdMediumWoff2}) format('woff2'),
      url(${CircularStdMediumWoff}) format('woff');
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: CircularStd;
    font-weight: 700;
    font-style: normal;
    src: url(${CircularStdBlackWoff2}) format('woff2'), url(${CircularStdBlackWoff}) format('woff');
    font-display: swap;
  }

  * {
    font-size: 14px;
    font-family: CircularStd, Arial, Helvetica, sans-serif !important;
    font-weight: 300;

    @media (min-width: ${customTheme.breakpoints.md}) {
      font-size: 16px;
    }
  }

  p,
  h2 {
    opacity: 0.85;
  }
`

function Layout({ children, title, subtitle }) {
  const { language } = useI18next()
  const { t } = useTranslation()
  const [bookingParams, setBookingParams] = useRecoilState(bookingParamsState)
  const [modalParams, setModalParams] = useRecoilState(modalParamsState)

  const handleSubmit = async (values, setSuccess) => {
    const data = {
      FNAME: values.name,
      COMMENTS: values.comments,
      TYPEQUOTE: values.typeQuote,
    }

    if (values.checkIn !== '') data.CHECKIN = format(values.checkIn, 'dd/MM/yyyy')
    if (values.checkOut !== '') data.CHECKOUT = format(values.checkOut, 'dd/MM/yyyy')

    const res = await addToMailchimp(
      values.email,
      data,
      'https://anayjose.us19.list-manage.com/subscribe/post?u=913dbc71926ff10619b39e45b&amp;id=ad58fa0f6b'
    )

    if (res.result === 'success') setSuccess(true)
    else setSuccess(false)
  }

  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Global styles={globalStyles} />
      <Flex direction="column" minHeight="100vh" position="relative">
        <Header logo={<Logo />} nav={<Nav />} />
        <Box as="main" flex="1">
          {(title || subtitle) && (
            <Article backgroundColor="gray.50">
              <Flex
                as="section"
                direction="column"
                backgroundColor="gray.50"
                paddingY={{ base: 8, md: 12 }}
                paddingX={{ base: 6, md: 20, lg: 6, xl: 20 }}
                maxWidth={{ md: customTheme.breakpoints.lg, lg: customTheme.breakpoints.mw }}
                marginX="auto"
              >
                {title && <H1>{title}</H1>}
                {subtitle && <H2 marginTop={2}>{subtitle}</H2>}
              </Flex>
            </Article>
          )}
          {children}
        </Box>
        <Footer />
        <BookingModal
          lang={language}
          onClose={() => setBookingParams({ isOpen: false })}
          {...bookingParams}
        />
      </Flex>
      <WhatsappButton />
      <Modal
        wrapperStyle={{
          width: '100%',
          maxWidth: '1024px',
        }}
        isOpen={modalParams.isOpen}
        onClose={() => setModalParams({ isOpen: false })}
      >
        <Box marginBottom={6}>
          <H3>{t('commons.titleQuote')}</H3>
          <Text>{t('commons.descQuote')}</Text>
        </Box>
        <FormQuote
          hideOnSuccess
          lang={language}
          typeQuote="Casa de Agua"
          nameLabel={t('contact.form.name.label')}
          namePlaceholder={t('contact.form.name.placeholder')}
          emailLabel={t('contact.form.email.label')}
          emailPlaceholder={t('commons.emailPlaceholder')}
          emailRequiredText={t('contact.form.email.required')}
          emailInvalidText={t('contact.form.email.invalid')}
          checkInLabel={t('contact.form.checkIn.label')}
          checkOutLabel={t('contact.form.checkOut.label')}
          commentsLabel={t('contact.form.comments.label')}
          commentsPlaceholder={t('contact.form.comments.placeholder')}
          buttonText={t('contact.form.button')}
          successMessage={t('contact.form.successMessage')}
          onSubmit={handleSubmit}
        />
      </Modal>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
